/* GLOBAL */

body {
  margin: 0;
  background-color: white;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
}

.navbar {
  position:fixed;
  width: 250px;
  height: 100%;
  top: 0;
  left: 0;
  display:flex;
  flex-direction: column;
}

.top-collapse {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#logo-div {
  margin-top: 40px;
  margin-left: 37px;
}

#logo {
  font-size: 2em;
  font-weight: bold;
  letter-spacing: -0.05em;
  text-decoration: none;
  color: black;
}

#logo:hover {
  color: #403681;
}

.navbar ul {
  list-style: none;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 40px;
}

.navbar li {
  margin-bottom: 15px;
}

#nav-link {
  text-transform:uppercase;
  text-decoration: none;
  color: black
}

#nav-link.active {
  text-decoration: none;
  color: #403681;
}

#nav-link:hover {
  color: #403681;
}

#social-icons {
  width: 75%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
}

.fa {
  padding: 8px;
  width: 18px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  background-color: black;
  color: white;
}

#burger-div {
  height: 30px;
  width: 50px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}

.burger-bar {
  height: 5px;
  width: 100%;
  background-color: black;
}

#content {
  margin-left: 250px;
  width: 700px;
  padding-top: 45px;
}

/* HOME PAGE */

.announce-title {
  background-color: #403681;
  color: white;
  font-size: 1.8em;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.announce-body {
  display: flex;
  flex-direction: row;
}

.announce-body-one {
  width: 60%;
}

.announce-art {
  width: 100%;
}

.announce-body-two {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.announce-notes {
  margin-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  text-justify: distribute;
}

.announce-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.announce-button {
  background-color: black;
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px;
  margin: 15px;
  text-decoration: none;
  text-align: center;
}

/* MUSIC */

.release {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.release-one {
  width: 50%;
}

.release-art {
  width: 100%;
}

.release-two {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.release-title {
  font-size: 1.2em;
  margin-top: 0;
  margin-bottom: 5px;
  margin-left: 30px;

}

.release-date {
  margin-top: 0;
  margin-left: 30px;
}

.release-notes {
  font-family: monospace;
  font-size: 0.7em;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 30px;
}

.release-buttons {
  margin-top: 20px;
  margin-left: 30px;
  display: flex;
}

.release-button {
  background-color: black;
  color: white;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
  margin-right: 10px;

}

.release-button:hover {
  background-color: #404040;
  color: white;
  padding: 10px;
  text-decoration: none;
}

/* VIDEOS */

.youtube-video {
  margin-bottom: 30px;
}

/* PRESS SHOTS */

.press-shot {
  width: 100%;
  margin-bottom: 0;
}

.press-shot-caption {
  margin-top: 0;
  margin-bottom: 30px;
  text-align: right;
  font-family: monospace;
}

/* CONTACT */

#mc_embed_signup {
  font:14px Helvetica,Arial,sans-serif;
  background-color: lightgray;
  padding: 20px;
  width: 50%;
}

.email-address {
  color: #403681;
}

.contact-blurb {
  margin-top: 0;
}

/* SHOWS */

.shows-blurb {
  margin-top: 0;
}

.shows-link {
  text-decoration: none;
  color: black;
}

.shows-link:hover {
  color: #403681;
}

.show {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: monospace;
  padding: 5px;
}

.show-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
}

.show p {
  margin: 0;

}

.show-date {
  font-weight: bold;
}

.show-geo {
  font-style: italic;
}

.show-button {
  background-color: black;
  color: white;
  padding: 10px;
  text-decoration: none;
  margin-left: 10px;
}

.show-button:hover {
  background-color: #404040;
  color: white;
  padding: 10px;
  text-decoration: none;
  margin-left: 10px;
}

/* LYRICS */

.song p {
  margin: 0;
}

.song h3 {
  margin-bottom: 5px;
}

.song {
  margin-bottom: 30px;
}

/* BIO */

.elevator-pitch {
  display: flex;
  flex-direction: row;
}

.bio-image {
  width: 100%;
}

.elevator-pitch-text {
  margin-top: 0;
  margin-left: 30px;
  text-align: justify;
  text-justify: inter-word;
}

.bio-text {
  text-align: justify;
  text-justify: inter-word;
}

@media screen and (max-width: 800px) {
  .navbar {
    width: 100%;
    height: auto;
    position: relative;
  }

  #burger-div {
    display: flex;
    margin-top: 25px;
    margin-right: 30px;
    margin-bottom: 30px;
  }
  
  #nav-links {
    overflow: hidden;
    margin-top: -100%;
    background-color: lightGray;
    text-align: right;
    transition: 0.5s;
  }

  #nav-links.active {
    margin-top: 0;
  }
  
  .navbar ul {
    margin-top: 30px;
    margin-bottom: 0;
  }

  #nav-links li {
    margin-right: 30px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  #social-icons {
    display: none;
  }

  #content {
    margin-top: 80px;
    margin-left: 0;
    padding: 0;
    width: 100%;
    transition: 0.2s;
  }

  #content.expanded {
    margin-top: 0;
  }

  #logo-div {
    margin-top: 15px;
    margin-left: 20px;
  }

  #logo {
    font-size: 2.5em;
  }

  .navbar ul {
    margin-bottom: 30px;
  }

  .announce-body, .release {
    flex-direction: column;
  }

  .announce-body-one, .release-one {
    width: 100%;
  }

  .announce-body-two, .release-two {
    width: 100%;
    margin-top: 15px;
  }

  .release-two {
    align-items: center;
  }

  .shows-blurb {
    text-align: center;
  }

  .shows {
    padding-left: 15px;
  }

  .show-buttons {
    margin-right: 40px;
  }

  .show-location {
    margin-left: 20px;
  }

  .bio-text {
    padding-left: 15px;
    padding-right: 15px;
  }
}
